<template>
  <div></div>
</template>
<script>
export default {
  beforeMount() {
    this.$router.replace({
      path: '/assessment-instances',
    })
  }
}
</script>